
  import {
    defineComponent,
    onMounted,
    ref,
  } from 'vue';
  import image1 from '@/assets/images/home/throne.png';
  import image2 from '@/assets/images/home/sweetChase.png';
  import SvgIcon from '@/components/SvgIcon.vue';
  import Web3Provider from '../../utils/Web3Provider';
  import {
    formatCurrency,
  } from '@/utils/baseUtils';
  import {
    JKT_TOKEN_ADDRESS,
  } from '@/const/address/tokenAddress';
  import BigNumber from 'bignumber.js';
  import {
    copyToClipboard,
  } from '@/utils/clipboard';

  export default defineComponent({
    name: 'home',
    components: {
      SvgIcon,
    },
    setup() {
      const images = [
        image1, image2
      ];
      const jktTokenAddress = ref(JKT_TOKEN_ADDRESS);
      const totalJKT = ref(formatCurrency(1000000000));
      const totalBurn = ref();
      const totalJKTSupply = ref();
      totalBurn.value = 'Loading...';
      totalJKTSupply.value = 'Loading...';
      onMounted(async () => {
        const [total, decimal] = await Promise.all([Web3Provider.getInstance().getJKTTotal(), Web3Provider.getInstance().getJKTDecimals()]);
        totalJKTSupply.value = formatCurrency(new BigNumber(total).div(new BigNumber(10).pow(decimal)));
        const hashInfo = await Web3Provider.getInstance().getHashInformation();
        if (hashInfo) {
          totalBurn.value = formatCurrency(new BigNumber(hashInfo.eLpBurn).plus(new BigNumber(hashInfo.eVipBurn)).div(new BigNumber(10).pow(decimal)));
        }

      });
      return {
        images,
        jktTokenAddress,
        totalJKT,
        totalBurn,
        totalJKTSupply,
        window,
        copyToClipboard,
      };
    },
  });
